import { Component, OnInit } from '@angular/core';
import { NavBarItemModel } from '@core/models/component-models/navbar.model';
import { MenubarModule } from 'primeng/menubar';
import { CommonModule } from '@angular/common';
import { MultiCheckboxComponent } from '@shared/checkbox/multi-checkbox/multi-checkbox.component';
import { RadioButtonComponent } from '@shared/radio-button/radio-button.component';
import { Router } from '@angular/router';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {environment} from "../../../environments/environment";
import {AuthenticationService} from "@core/services/authentication.service";
import {SignalService} from "@core/services/signal.service";
import {MapType} from "@core/services/map.service";
import {subNavbar} from "../../app.props";

@Component({
  selector: 'app-profile-dropdown',
  standalone: true,
  imports: [
    MenubarModule,
    CommonModule,
    MultiCheckboxComponent,
    RadioButtonComponent,
    ReactiveFormsModule,
    TranslateModule,
  ],
  templateUrl: './profile-dropdown.component.html',
  styleUrl: './profile-dropdown.component.scss',
})
export class ProfileDropdownComponent implements OnInit {
  items: NavBarItemModel[] = subNavbar;
  checkControl = new FormControl();
  radioControl = new FormControl();

  constructor(
    public router: Router,
    private authenticationService: AuthenticationService,
    private signalService: SignalService
    ) {
  }

  ngOnInit(): void {
    this.radioControl.setValue(this.signalService.tileSignal())
    if (environment.isLocal) {
      this.items[0].items?.push({
        label: 'NAVBAR.SUB_NAVBAR.DEMO',
        icon: 'pi pi-desktop',
        key: 'DEMO'
      });
    }
    this.checkControl.valueChanges.subscribe(() => {
      //to be defined later;
    });

    this.radioControl.valueChanges.subscribe((res) => {
      this.signalService.tileSignal.set(res as MapType)
    });
  }

  async onItemClick(event: Event, item: NavBarItemModel) {
    if (event.defaultPrevented) {
      event.stopPropagation();
    }
    if (item.items?.length) return;
    switch (item.key) {
      case 'PROFILE':
        this.router.navigate(['profile']);
        break;
      case 'SIGN_OUT':
        await this.authenticationService.logout();
        break;
      case 'DEMO':
        this.router.navigate(['demo']);
        break;
    }
  }
}
