import {NavBarItemModel} from '@core/models/component-models/navbar.model';
import {MapType} from "@core/services/map.service";

export const navbarItems: NavBarItemModel[] = [
  {
    label: 'NAVBAR.EMPLOYEES.MAIN',
    image: 'assets/header-icons/employee.svg',
    permission: 'EMPLOYEES',
    items: [
      {
        routerLink: '/employees/employees',
        label: 'NAVBAR.EMPLOYEES.EMPLOYEES',
      },
      {
        routerLink: '/employees/positions',
        label: 'NAVBAR.EMPLOYEES.POSITIONS',
      }
    ],
  },
  {
    label: 'NAVBAR.VEHICLES.MAIN',
    image: 'assets/header-icons/vehicles.svg',
    permission: 'VEHICLES',
    items: [
      {
        routerLink: '/vehicles/fleet',
        label: 'NAVBAR.VEHICLES.FLEETS',
      },
      {
        routerLink: '/vehicles/vehicle-types',
        label: 'NAVBAR.VEHICLES.VEHICLE_TYPES',
      },
      {
        routerLink: '/vehicles/fuel-types',
        label: 'NAVBAR.VEHICLES.FUEL_TYPES',
      },
      {
        routerLink: '/vehicles/tank-dimensions',
        label: 'NAVBAR.VEHICLES.TANK_DIMENSIONS',
      },
      {
        routerLink: '/vehicles/brands',
        label: 'NAVBAR.VEHICLES.BRANDS_AND_MODELS',
      }
    ],
  },
  {
    label: 'NAVBAR.LOCATIONS.MAIN',
    image: 'assets/header-icons/locations.svg',
    permission: 'LOCATIONS',
    items: [
      {
        routerLink: '/locations/location-types',
        label: 'NAVBAR.LOCATIONS.LOCATION_TYPES',
      },
      {
        routerLink: '/locations/location',
        label: 'NAVBAR.LOCATIONS.LOCATIONS',
      }
    ]
  },
  {
    label: 'NAVBAR.ROUTES.MAIN',
    image: 'assets/header-icons/route.svg',
    permission: 'ROUTES',
    items: [
      {
        routerLink: '/routes/services',
        label: 'NAVBAR.ROUTES.SERVICES',
      },
    ],
  },
  {
    label: 'NAVBAR.BINS.MAIN',
    image: 'assets/header-icons/bins.svg',
    permission: 'BINS',
    items: [
      {
        routerLink: '/bins/bins',
        label: 'NAVBAR.BINS.BINS',
      },
      {
        routerLink: '/bins/waste-types',
        label: 'NAVBAR.BINS.WASTE_TYPES',
      },
      {
        routerLink: '/bins/bin-types',
        label: 'NAVBAR.BINS.BIN_TYPES',
      },
    ],
  },
  {
    label: 'NAVBAR.REQUESTS.MAIN',
    image: 'assets/header-icons/requests.svg',
    permission: 'REQUESTS',
    items: [
      {
        routerLink: '/requests/request-type',
        label: 'NAVBAR.REQUESTS.REQUEST_TYPE',
      },
      {
        routerLink: '/requests/request-mode',
        label: 'NAVBAR.REQUESTS.REQUEST_MODE',
      }
    ]
  },
  {
    label: 'NAVBAR.SETTINGS.MAIN',
    image: 'assets/header-icons/settings.svg',
    permission: 'SETTINGS',
    items: [
      {
        routerLink: '/settings/organization',
        label: 'NAVBAR.SETTINGS.ORGANIZATION',
      },
      {
        routerLink: '/settings/access-branches',
        label: 'NAVBAR.SETTINGS.ACCESS_BRANCHES',
      },
      {
        routerLink: '/settings/access_roles',
        label: 'NAVBAR.SETTINGS.ACCESS_ROLES',
      }
    ],
  },
];


const keys: MapType[] = ['osm', 'mapBox_satellite',"mapBox_traffic","esri_satellite"];

const mapTypeButtons = keys.map(key => ({
  key,
  label: key.replace('_',' ')
}));

export const subNavbar:NavBarItemModel[] = [
  {
    image: 'assets/header-icons/user-icon.png',
    items: [
      {
        label: 'NAVBAR.SUB_NAVBAR.PROFILE',
        icon: 'pi pi-user',
        key: 'PROFILE',
      },
      {
        label: 'NAVBAR.SUB_NAVBAR.BRANCHES',
        icon: 'pi pi-building',
        items: [],
      },
      {
        label: 'NAVBAR.SUB_NAVBAR.MAP_TYPE',
        icon: 'pi pi-map',
        items: [
          {
            radioGroup: mapTypeButtons,
          },
        ],
      },
      {
        label: 'NAVBAR.SUB_NAVBAR.SIGN_OUT',
        icon: 'pi pi-sign-out',
        key: 'SIGN_OUT',

      },
    ],
  },
];
