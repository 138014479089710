import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID,} from '@angular/core';
import {NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {NavbarComponent} from './components/navbar/navbar.component';
import {ProfileDropdownComponent} from './components/profile-dropdown/profile-dropdown.component';
import {navbarItems} from './app.props';
import {I18nService} from '@core/services/i18n/i18n.service';
import {ToastModule} from "primeng/toast";
import {isPlatformBrowser} from "@angular/common";
import {Subscription} from "rxjs";
import {MetaService} from "@core/services/meta.service";
import {AuthenticationService} from "@core/services/authentication.service";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NavbarComponent, ProfileDropdownComponent, ToastModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
  navbarItems = navbarItems;
  isLoginPage: boolean = true;
  subscription: Subscription = new Subscription();
  direction: 'rtl' | 'ltr' = 'ltr';

  constructor(private i18n: I18nService,
              metaService: MetaService,
              private router: Router,
              private cd: ChangeDetectorRef, // Inject ChangeDetectorRef
              private auth: AuthenticationService,
              @Inject(PLATFORM_ID) private readonly platformId: Object) {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.auth.isUserAuthenticated()
    this.i18n.setUpLanguages();
    if (isPlatformBrowser(this.platformId)) {
      const body = document.getElementsByTagName('body')[0]
      this.i18n.onLangChange().subscribe((res) => {
        body.className= res.lang === 'ar' ?'rtl':''
        this.cd.detectChanges();
      })
    }
    this.checkIfLoginPage();
  }

  checkIfLoginPage(): void {
    this.subscription.add(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.isLoginPage = event.url === "/login";
        }
      })
    );
  }
}
